// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";

import { ShortDate } from "../components/date";

const ComingSoonSection = () => (
    <section className="coming-soon__intro flex-container">

        <div className="flex-container flex-container--columns container--large highlight-links">
            <h1>Coming Soon to This Site</h1>
            <ul>
                <li>
                    <s style={{opacity: 0.8}}><em>The New Toys</em> Blog</s> - <Link to="/blog">done</Link>!
                </li>
                <li>
                    Updates on the latest new JavaScript features. In the meantime, see these blog posts:
                    <ul>
                        <li>
                            <ShortDate v={"2020-07-25T17:45:00.000Z"}/>: <Link to="/blog/2020/07/25/the-item-method-for-indexables"><em>The <code>item</code> Method for Indexables</em></Link>
                        </li>
                        <li>
                            <ShortDate v={"2020-07-21T15:05:00.000Z"}/>: <Link to="/blog/2020/07/21/logical-assignment-operators"><em>New: Logical Assignment
                            Operators</em></Link>
                        </li>
                    </ul>
                </li>
                <li>
                    Coverage of
                    the <a href="https://www.ecma-international.org/ecma-402/">
                        ECMAScript Internationalization API Specification
                    </a>
                </li>
                <li>
                    Themes: high-contrast, light, and dark
                </li>
                <li>
                    JavaScript Q&amp;A
                </li>
            </ul>
        </div>
    
    </section> 
);

const ComingSoonPage = () => (
    <Layout>
        <PageInfo title="Coming Soon" />
        <ComingSoonSection />
    </Layout>
);

export default ComingSoonPage;
