// The format for an ISO-8601 full date/time, useful for graphql queries.
export const isoDateFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";

/**
 * Flag for whether the current environment has formatter support (of the kind we want).
 * Note: We use `dateStyle` but not `timeStyle`.
 */
export const hasDateFormatterSupport =
    typeof Intl === "object" &&
    Intl &&
    Intl.DateTimeFormat &&
    (new Intl.DateTimeFormat(undefined, {dateStyle: "full"}).format(new Date(0))
        !== new Intl.DateTimeFormat(undefined, {dateStyle: "short"}).format(new Date(0)));

function digits(num, count = 2) {
    return String(num).padStart(count, "0");
}

function twoDigits(num) {
    return digits(num, 2);
}

function fourDigits(num) {
    return digits(num, 4);
}

/**
 * Get the TZ suffix (+01:00, -08:00) for the given date in local time.
 * WARNING: When generating static files, never use local time!
 *
 * @param {Date} dt The date.
 * @returns The suffix.
 */
export function getTZSuffix(dt) {
    let suffix;
    const offset = dt.getTimezoneOffset();
    if (offset < 0) {
        suffix = "+"; // Yes, really +
        offset = -offset;
    } else {
        suffix = "-"; // Yes, really -
    }
    suffix += twoDigits(Math.floor(offset / 60)) + ":" + twoDigits(offset % 60);
    return suffix;
}

/**
 * Gets an Intl.DateTimeFormatter if available and if it supports `dateStyle` (if the arguments
 * use it).
 * 
 * @param  {...any} args The arguments to give.
 * @returns {DateTimeFormat|undefined} The formatter, or `undefined` if not supported.
 */
export function createDateTimeFormatter(...args) {
    if (args.dateStyle && !hasDateFormatterSupport) {
        return;
    }
    return new Intl.DateTimeFormat(undefined, ...args);
}

/**
 * Creates a `Intl.DateTimeFormat` instance and returns the `format` function bound to that
 * instance, or a "universal" format function if `Intl.DateTimeFormat` isn't supported or doesn't
 * have the features we want.
 *
 * @param  {...any} args The arguments to give `Intl.DateTimeFormat`.
 * @returns {Function}
 */
function getDateTimeFormatFunction(...args) {
    const formatter = createDateTimeFormatter(...args);
    let fn;
    if (formatter) {
        fn = formatter.format.bind(formatter);
    } else if (args.timeZone === "UTC") {
        fn = formatUniversalUTCDate;
    } else {
        fn = formatUniversalLocalDate;
    }
    return fn;
}

const basicFormatDate = getDateTimeFormatFunction({dateStyle: "long", timeZone: "UTC"})
const basicFormatMediumDate = getDateTimeFormatFunction({dateStyle: "medium", timeZone: "UTC"})
const basicFormatShortDate = getDateTimeFormatFunction({dateStyle: "short", timeZone: "UTC"})

/**
 * Formats just the time portion (UTC) of the given date, including a " UTC" suffix.
 *
 * @param {Date} dt The date.
 * @param {boolean} suffix (Optional, default true) true to include the UTC suffix, false to leave it off
 */
export function formatTime(dt, suffix = true) {
    const str = twoDigits(dt.getUTCHours()) + 
        ":" + 
        twoDigits(dt.getUTCMinutes());
    if (suffix) {
        return str + " UTC";
    }
    return str;
}

/**
 * Formats just the time portion (local time) of the given date, including a " UTC" suffix.
 * WARNING: When generating static files, never use local time!
 *
 * @param {Date} dt The date.
 * @param {boolean} suffix (Optional, default true) true to include the UTC suffix, false to leave it off
 */
export function formatLocalTime(dt, suffix = true) {
    const str = twoDigits(dt.getHours()) +
        ":" + 
        twoDigits(dt.getMinutes());
    if (suffix) {
        return str + " " + getTZSuffix(dt);
    }
    return str;
}

/**
 * Formats a local date into a universal form that doesn't rely on locale.
 * WARNING: When generating static files, never use local time!
 *
 * @param {Date} dt The date.
 * @returns The formatted date.
 */
export function formatUniversalLocalDate(dt) {
    return fourDigits(dt.getFullYear()) + "-" +
        twoDigits(dt.getMonth() + 1) + "-" +
        twoDigits(dt.getDate());
}

/**
 * Formats a local date/time into a universal form that doesn't rely on locale.
 * WARNING: When generating static files, never use local time!
 *
 * @param {Date} dt The date.
 * @returns The formatted date/time.
 */
export function formatUniversalLocalDateTime(dt) {
    return fourDigits(dt.getFullYear()) + "-" +
        twoDigits(dt.getMonth() + 1) + "-" +
        twoDigits(dt.getDate()) + " " +
        formatTime(dt);
}

/**
 * Formats a UTC date into a universal form that doesn't rely on locale.
 *
 * @param {Date} dt The date.
 * @returns The formatted date.
 */
export function formatUniversalUTCDate(dt) {
    return fourDigits(dt.getUTCFullYear()) + "-" +
        twoDigits(dt.getUTCMonth() + 1) + "-" +
        twoDigits(dt.getUTCDate());
}

/**
 * Formats a UTC date/time into a universal form that doesn't rely on locale.
 *
 * @param {Date} dt The date.
 * @returns The formatted date/time.
 */
export function formatUniversalUTCDateTime(dt) {
    return fourDigits(dt.getUTCFullYear()) + "-" +
        twoDigits(dt.getUTCMonth() + 1) + "-" +
        twoDigits(dt.getUTCDate()) + " " +
        formatTime(dt);
}

/**
 * Format the given date (only) in our standard date format using UTC.
 *
 * @param {Date} dt The date.
 * @returns The formatted date.
 */
export const formatDate = (dt) => `${basicFormatDate(dt)}`;

/**
 * Format the given date (only) in our "medium" format using UTC.
 *
 * @param {Date} dt The date.
 * @returns The formatted date.
 */
export const formatMediumDate = (dt) => `${basicFormatMediumDate(dt)}`;

/**
 * Format the given date (only) in our "short" format using UTC.
 *
 * @param {Date} dt The date.
 * @returns The formatted date.
 */
export const formatShortDate = (dt) => `${basicFormatShortDate(dt)}`;

/**
 * Format the given date in our standard date/time format using UTC.
 *
 * @param {Date} dt The date.
 * @returns The formatted date/time.
 */
export const formatDateTime = (dt) => `${basicFormatDate(dt)} ${formatTime(dt)}`;

/**
 * Format the given date in our "medium" date/time format using UTC.
 *
 * @param {Date} dt The date.
 * @returns The formatted date/time.
 */
export const formatMediumDateTime = (dt) => `${basicFormatMediumDate(dt)} ${formatTime(dt)}`;

/**
 * Format the given date in our "short" date/time format using UTC.
 *
 * @param {Date} dt The date.
 * @returns The formatted date/time.
 */
export const formatShortDateTime = (dt) => `${basicFormatShortDate(dt)} ${formatTime(dt)}`;

// Regex to parse JS-style dates; see `getDateParts`
const rexJSDateTime = /^(\d{4})(?:[\/-](\d{1,2})(?:[\/-](\d{1,2})(?:[T ](?:(\d{1,2})(?::(\d{1,2})(?::(\d{1,2})(?:\.(\d{1,3}))?)?)?)?)?)?)?(?:(Z|[+-]\d{2}:\d{2}))?$/;

/**
 * Gets an [year, month, day, hours, minutes, seconds, ms, tz] array from the given string, if it's
 * in JavaScript date format. Everything starting with hours is optional and defaulted to zero (tz
 * is defaulted to `undefined`)
 *
 * @param {string} str The string to parse.
 * @returns The array, or `null` if the string isn't in the expected format.
 */
export function getDateParts(str) {
    const parts = rexJSDateTime.exec(str.trim());
    if (!parts) {
        return null;
    }
    // After slice, index 7 is the timezone (if any); all others are numeric strings or undefined
    return parts.slice(1).map((p, i) => i === 7 ? p : +p || 0);
}

/**
 * Parses a JavaScript date/time string always using the same defaults (not different ones based on
 * whether it's a date-only or date/time string).
 *
 * @param {string} str The string to parse.
 * @param {boolean} utc (Optional, default `false`) `true` to default to UTC instead of local time
 * if there's no timezone on the string.
 * @returns The `Date`, which may have `NaN` as its time value (e.g., "invalid date")
 */
export function parseDate(str, utc = false) {
    const parts = getDateParts(str);
    if (!parts) {
        return new Date(NaN);
    }
    const [year, month, day, hour, minute, second, ms, tz] = parts;
    if (tz) {
        return new Date(str);
    }
    if (month === 0 || day === 0) {
        return new Date(NaN);
    }
    if (utc) {
        return new Date(Date.UTC(year, month - 1, day, hour, minute, second, ms));
    }
    return new Date(year, month - 1, day, hour, minute, second, ms);
}

const tweetParts = ["url", "hashtags", "via", "text"];
const rexHasScheme = /^[a-z]\+:\/\//;
/**
 * Gets a URL for doing a tweet.
 *
 * @param {string|{url?: string, text?: string, via?: string, hashtags?: string}} params The information for the URL.
 * @returns The URL.
 */
export function getTweetUrl(params) {
    if (typeof params !== "object") {
        params = {url: params};
    }
    const parts = tweetParts
        .filter(name => params[name])
        .map(name => {
            let value = params[name];
            if (name == "url" && !rexHasScheme.test(value)) {
                if (!value.startsWith("/")) {
                    value = "/" + value;
                }
                value = `https://thenewtoys.dev${value}`;
            }
            return `${name}=${encodeURIComponent(value)}`;
        });
    if (!parts.length) {
        throw new Error(
            `getTweetUrl requires either a string or an object with at least one of ${tweetParts.join(", ")}`
        );
    }
    let tweetUrl = `https://twitter.com/intent/tweet?${parts.join("&")}`;
    return tweetUrl;
}