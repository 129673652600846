import React, { useState, useEffect } from "react";

import {
    hasDateFormatterSupport,
    parseDate,
    formatDate,
    formatDateTime,
    formatMediumDate,
    formatMediumDateTime,
    formatShortDate,
    formatShortDateTime,
    formatUniversalUTCDateTime,
    formatUniversalUTCDate
} from "../common/utils";

export const StdDate = makeComponent(formatDate, "std-date");
export const StdDateTime = makeComponent(formatDateTime, "std-date-time");
export const MediumDate = makeComponent(formatMediumDate, "medium-date");
export const MediumDateTime = makeComponent(formatMediumDateTime, "medium-date-time");
export const ShortDate = makeComponent(formatShortDate, "short-date");
export const ShortDateTime = makeComponent(formatShortDateTime, "short-date-time");

function makeComponent(format, cls) {
    return ({v}) => {
        const dt = parseDate(v);
        const [display, setDisplay] = useState(cls && cls.endsWith("-time") ? formatUniversalUTCDateTime(dt) : formatUniversalUTCDate(dt));
        useEffect(() => {
            if (hasDateFormatterSupport) {
                setDisplay(format(dt));
            }
        }, []);
        return <span className={cls} title={v}>{display}</span>;
    };
}